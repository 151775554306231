<div class="topbar">
    <div class="left">
        <ui-logo [small]="true" />
    </div>
    <div class="center">
        <h3>NotificationService DEMO</h3>
    </div>
    <div class="right">
        <ns-notifications-component />
    </div>
</div>
<section>
    <div class="recipient">
        <span> Insert user ID to track (defaults to current)</span>
        <ui-input
            type="text"
            (submit)="userIdSelected()"
            [(value)]="currentUserId" />
    </div>
    <div class="test-data">
        <span> Use test data </span>
        <ui-toggle-switch
            [selected]="useTestData()"
            (selectedChange)="updateUseTestData()" />
    </div>
    <div class="test-notification">
        <span>Trigger a test notification</span>
        <ui-button
            type="primary"
            (click)="triggerTestNotification()"
            text="submit" />
    </div>
</section>
