import { InjectionToken } from '@angular/core';
import { Subject } from 'rxjs';

export const CONFIG_TOKEN = new InjectionToken<NotificationModuleOptions>('config');

export interface NotificationModuleOptions {
    apiUri: string;
    userId$: Subject<string | undefined>;
    accessToken$: Subject<string | undefined>;
    useTestData: boolean;
    signalR: {
        enabled: boolean;
        url: string;
    };
}
