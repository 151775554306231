import { Environment } from './environment.type';

export const environment: Environment = {
    auth0: {
        loginUrl: 'https://login.bannerflow.com',
        clientId: 'IprB55rjky2nrKA4Dyem7vmCKnpR5bFT'
    },
    origins: {
        apiUri: 'https://api.bannerflow.com/notification-service/api'
    },
    signalR: {
        enabled: true,
        url: 'https://api.bannerflow.com/notification-service/hub'
    }
};
