@if (loading()) {
    <div class="wrapper">
        <ui-loader [inline]="true" />
    </div>
} @else {
    @if (canLoadMore()) {
        <div class="wrapper">
            <ui-button
                text="load more"
                type="discrete"
                (click)="loadMore()" />
        </div>
    }
}
