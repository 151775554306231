<ui-popover
    #popover="ui-popover"
    [config]="{
        minWidth: '235px',
        arrowPosition: 'top',
        position: 'bottom',
        panelClass: 'no-padding',
        offset: { x: 0, y: 0 }
    }">
    <ng-template ui-popover-template>
        <ns-popover-header />
        <ns-notifications-list />
        <ns-popover-footer />
    </ng-template>
</ui-popover>
