<div class="wrapper">
    <h4>Notifications</h4>

    <div class="filter">
        <span> Filter read </span>
        <ui-toggle-switch
            [selected]="currentFilter() === 'showUnread'"
            (selectedChange)="toggleFilter($event)" />
    </div>
    <div class="refresh">
        <ui-svg-icon
            icon="sync-l"
            (click)="refreshNotifications()" />
    </div>
</div>
