import { Component, inject, OnInit, signal, viewChild } from '@angular/core';
import { UIModule, UIPopoverDirective, UIPopoverRef, UIPopoverTargetDirective } from '@bannerflow/ui';
import { NotificationDto } from '../../../domain';
import { NotificationService } from '../../../services/notification.service';
import { NotificationsListComponent } from '../notifications-list/notifications-list.component';
import { PopoverFooterComponent } from './popover-footer/popover-footer.component';
import { PopoverHeaderComponent } from './popover-header/popover-header.component';

@Component({
    standalone: true,
    imports: [UIModule, NotificationsListComponent, PopoverFooterComponent, PopoverHeaderComponent],
    selector: 'ns-notification-popover',
    templateUrl: './notification-popover.component.html',
    styleUrl: './notification-popover.component.scss'
})
export class NotificationPopoverComponent implements OnInit {
    private notificationsService = inject(NotificationService);

    private popover = viewChild.required<UIPopoverDirective>('popover');

    loadingMore = signal(false);
    error = signal<unknown | undefined>(undefined);

    notifications = signal<NotificationDto[]>([]);

    ngOnInit(): void {
        this.loadNotifications();
    }

    openPopover(target: UIPopoverTargetDirective): UIPopoverRef {
        const popover = this.popover();
        if (!popover) {
            throw new Error('Could not find popover child');
        }
        const popoverRef = popover.open(target);
        return popoverRef;
    }

    private loadNotifications(): void {
        this.loadingMore.set(true);
        this.notificationsService.loadMoreNotifications();
    }
}
