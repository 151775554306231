import { Component, computed, inject, input } from '@angular/core';
import { UIModule } from '@bannerflow/ui';
import { NotificationDto } from '../../../domain';
import { NotificationService } from '../../../services/notification.service';
import { SenderIconComponent } from './sender-icon/sender-icon.component';

@Component({
    standalone: true,
    imports: [UIModule, SenderIconComponent],
    selector: 'ns-notification-item',
    templateUrl: './notification-item.component.html',
    styleUrl: './notification-item.component.scss'
})
export class NotificationItemComponent {
    private notificationService = inject(NotificationService);

    notification = input.required<NotificationDto>();
    initials = computed(() => this.computeInitials());
    iconColor = computed(() => this.computeIconColor());

    private readonly sysColor = '--ui-static-color-sky';
    private readonly externalColors = [
        '--ui-static-color-blue',
        '--ui-static-color-midnight',
        '--ui-static-color-arctic',
        '--ui-static-color-rain',
        '--ui-static-color-aqua',
        '--ui-static-color-salmon',
        '--ui-static-color-deep-red',
        '--ui-static-color-indian-red',
        '--ui-static-color-peach',
        '--ui-static-color-golden',
        '--ui-static-color-yellow',
        '--ui-static-color-olive',
        '--ui-static-color-grass',
        '--ui-static-color-purple'
    ];

    openLink(): void {
        const notification = this.notification();
        if (notification.content.link) {
            window.open(notification.content.link, '_blank')?.focus();
        }
    }

    markAsRead(event: Event): void {
        event.stopPropagation();
        const notification = this.notification();
        this.notificationService.markAsRead(notification);
    }

    private computeInitials(): string {
        const notification = this.notification();
        if (notification.sender.type === 'system') {
            return 'Sys';
        }
        return notification.sender.name?.substring(0, 2) ?? '?';
    }
    private computeIconColor(): string {
        const notification = this.notification();
        if (notification.sender.type === 'system') {
            return this.sysColor;
        }

        const randomIndex = Math.floor(Math.random() * this.externalColors.length);
        return this.externalColors[randomIndex];
    }
}
