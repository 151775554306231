import { Component, inject } from '@angular/core';
import { UIModule } from '@bannerflow/ui';
import { NotificationService } from '../../../services/notification.service';
import { NotificationItemComponent } from '../notification-item/notification-item.component';

@Component({
    standalone: true,
    imports: [UIModule, NotificationItemComponent],
    selector: 'ns-notifications-list',
    templateUrl: './notifications-list.component.html',
    styleUrl: './notifications-list.component.scss'
})
export class NotificationsListComponent {
    private notificationService = inject(NotificationService);

    notifications = this.notificationService.notifications;
}
