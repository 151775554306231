import { Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { AuthGuard } from '@auth0/auth0-angular';

export const routes: Routes = [
    {
        path: '',
        component: AppComponent,
        canActivate: [AuthGuard]
    }
];
