import { Component, computed, input } from '@angular/core';

@Component({
    standalone: true,
    imports: [],
    selector: 'ns-sender-icon',
    templateUrl: './sender-icon.component.html',
    styleUrl: './sender-icon.component.scss'
})
export class SenderIconComponent {
    initials = input.required<string>();
    color = input.required<string>();
    bgColor = computed(() => `var(${this.color()})`);
}
