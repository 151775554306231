import { Component, inject } from '@angular/core';
import { UIModule } from '@bannerflow/ui';
import { NotificationService } from '../../../../services/notification.service';

@Component({
    standalone: true,
    imports: [UIModule],
    selector: 'ns-popover-header',
    templateUrl: './popover-header.component.html',
    styleUrl: './popover-header.component.scss'
})
export class PopoverHeaderComponent {
    private notificationService = inject(NotificationService);

    currentFilter = this.notificationService.currentFilter;

    toggleFilter(showUnread: boolean): void {
        this.notificationService.setFilter(showUnread ? 'showUnread' : 'showAll');
    }

    refreshNotifications(): void {
        this.notificationService.refreshNotifications();
    }
}
