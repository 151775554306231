<div
    class="wrapper"
    ui-popover-target
    #notificationPopoverTarget="ui-popover-target">
    <div
        class="button"
        [class.disabled]="disabled()"
        tabindex="0"
        (keypress.enter)="bellClicked()"
        (keypress.space)="bellClicked()"
        (click)="bellClicked()">
        <ui-icon icon="bell" />

        @let _numberOfUnread = numberOfUnread();
        @if (_numberOfUnread) {
            <div class="unread-count">{{ _numberOfUnread }}</div>
        }
    </div>
</div>
<ns-notification-popover #notificationPopover />
