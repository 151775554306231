import { HttpClient } from '@angular/common/http';
import { Component, effect, inject, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { RouterOutlet } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { CONFIG_TOKEN, NotificationModule } from '@bannerflow/notification';
import { UIModule } from '@bannerflow/ui';
import { environment } from '../environments/environment';

@Component({
    selector: 'app-root',
    standalone: true,
    imports: [RouterOutlet, UIModule, NotificationModule],
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss'
})
export class AppComponent {
    private authService = inject(AuthService);
    private httpClient = inject(HttpClient);
    private libConfig = inject(CONFIG_TOKEN);

    private user = toSignal(this.authService.user$);

    title = 'NotificationService-demo';
    currentUserId = '';
    useTestData = signal(false);

    constructor() {
        this.useTestData.set(window.location.search.includes('useTestData=true'));
        effect(
            () => {
                const user = this.user();

                this.currentUserId = user?.['userId'] ?? '';

                this.libConfig.userId$.next(user?.['userId']);
                if (user) {
                    this.authService
                        .getAccessTokenSilently({
                            cacheMode: 'on'
                        })
                        .subscribe(accessToken => {
                            this.libConfig.accessToken$.next(accessToken);
                        });
                }
            },
            {
                // needs to write a signal inside lib
                allowSignalWrites: true
            }
        );
    }

    updateUseTestData(): void {
        window.location.search = `useTestData=${!this.useTestData()}`;
    }

    userIdSelected(): void {
        if (!this.currentUserId) {
            this.currentUserId = this.user()?.['userId'] ?? '';
        }
        this.libConfig.userId$.next(this.currentUserId);
    }

    triggerTestNotification(): void {
        const url = environment.origins['apiUri'] + '/notifications/test';
        this.httpClient.post(url, undefined).subscribe({
            next: () => console.log('NotificationCommand created'),
            error: error => console.error('NotificationCommand creation failed', error)
        });
    }
}
