import { AfterViewInit, Component, effect, inject, input, OnInit, viewChild } from '@angular/core';
import { UIModule, UINotificationService, UIPopoverTargetDirective } from '@bannerflow/ui';
import { NotificationType, SenderType } from '../../domain';
import { NotificationService } from '../../services/notification.service';
import { SignalRService } from '../../services/signalr.service';
import { NotificationPopoverComponent } from './notification-popover/notification-popover.component';

@Component({
    standalone: true,
    imports: [UIModule, NotificationPopoverComponent],
    selector: 'ns-notifications-component',
    templateUrl: './notifications.component.html',
    styleUrl: './notifications.component.scss'
})
export class NotificationsComponent implements OnInit, AfterViewInit {
    private notificationsService = inject(NotificationService);
    private signalRService = inject(SignalRService);
    private uiNotificationService = inject(UINotificationService);

    private notificationPopoverTarget = viewChild.required<UIPopoverTargetDirective>(
        'notificationPopoverTarget'
    );
    private notificationPopover =
        viewChild.required<NotificationPopoverComponent>('notificationPopover');

    disabled = input<boolean>();

    numberOfUnread = this.notificationsService.numberOfUnread;

    constructor() {
        effect(
            () => {
                const newNotification = this.signalRService.newNotification();
                if (newNotification) {
                    this.notificationsService.loadNumberOfUnreadNotifications();
                    this.notificationsService.loadAndPrependNotification(newNotification);
                }
            },
            { allowSignalWrites: true }
        );

        effect(() => {
            const newNotification = this.notificationsService.newNotification();
            if (newNotification) {
                const senderName =
                    newNotification.sender.type === SenderType.System
                        ? 'System'
                        : (newNotification.sender.name ?? '');
                const message = senderName
                    ? `New notification received from ${senderName} received`
                    : 'New notification received';
                const type =
                    newNotification.type === NotificationType.Information
                        ? 'success'
                        : newNotification.type;

                this.uiNotificationService.open(message, {
                    placement: 'top right',
                    type,
                    autoCloseDelay: 3000
                });
            }
        });
    }

    ngOnInit(): void {
        this.notificationsService.loadNumberOfUnreadNotifications();
    }

    ngAfterViewInit(): void {
        this.signalRService.startConnection();
    }

    bellClicked(): void {
        if (this.disabled()) {
            return;
        }
        const notificationPopover = this.notificationPopover();
        if (!notificationPopover) {
            return;
        }
        const target = this.notificationPopoverTarget();
        if (!target) {
            return;
        }
        notificationPopover.openPopover(target);
    }
}
