import { inject, Injectable } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { Subject } from 'rxjs';
import { NotificationReceivedEvent } from '../domain';
import { CONFIG_TOKEN } from '../notification.types';

@Injectable()
export class SignalRService {
    private readonly config = inject(CONFIG_TOKEN);
    private connection: HubConnection | undefined;

    private newNotification$ = new Subject<NotificationReceivedEvent>();

    newNotification = toSignal(this.newNotification$);

    constructor() {
        if (!this.config.signalR.enabled) {
            return;
        }

        this.config.accessToken$.pipe(takeUntilDestroyed()).subscribe(accessToken => {
            if (accessToken) {
                this.createConnection(accessToken);
            }
        });
    }
    startConnection(): void {
        this.connection
            ?.start()
            .then(() => {
                console.log('Connection succesful');
            })
            .catch(err => {
                console.error('An error has occurred while connecting', err);
            });
    }

    private createConnection(accessToken: string): void {
        const signalRHubUrl = this.config.signalR.url;

        if (this.connection) {
            return;
        }

        this.connection = new HubConnectionBuilder()
            .withUrl(signalRHubUrl, {
                accessTokenFactory: () => Promise.resolve(accessToken),
                withCredentials: true
            })
            .withAutomaticReconnect()
            .build();

        this.registerListeners();
        this.startConnection();
    }

    private registerListeners(): void {
        if (!this.connection) {
            return;
        }
        this.connection.on('NotificationReceived', (eventData: NotificationReceivedEvent) => {
            this.newNotification$.next(eventData);
        });
    }
}
