import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { map, Observable, of } from 'rxjs';
import {
    GetNumberOfUnreadNotificationsResponse,
    GetRecentNotificationsResponse,
    NotificationDto,
    NotificationsRecentListParams,
    GetNumberOfUnreadNotificationsRequest,
    NotificationType,
    TopicType,
    SenderType
} from '../domain';
import { CONFIG_TOKEN } from '../notification.types';
import { buildUrl } from '../utils/http.utls';
import { recentNotificationsMock, unreadNotificationsMock } from './mocked.data';

@Injectable()
export class NotificationDataService {
    private httpClient = inject(HttpClient);
    private readonly config = inject(CONFIG_TOKEN);

    loadMostRecentNotification(
        page = 1,
        itemsPerPage = 25,
        recipientId?: string
    ): Observable<GetRecentNotificationsResponse> {
        // TODO: Remove in the future
        if (this.config.useTestData) {
            return of(recentNotificationsMock);
        }
        const baseUrl = `${this.config.apiUri}/notifications/recent`;
        const params: NotificationsRecentListParams = {
            page,
            itemsPerPage,
            recipientId
        };

        const url = buildUrl(baseUrl, params);

        return this.httpClient.get<GetRecentNotificationsResponse>(url);
    }

    getNumberUnreadNotifications(
        recipientId?: string
    ): Observable<GetNumberOfUnreadNotificationsResponse> {
        // TODO: Remove in the future
        if (this.config.useTestData) {
            return of(unreadNotificationsMock);
        }
        const baseUrl = `${this.config.apiUri}/notifications/unread/count`;
        const params: GetNumberOfUnreadNotificationsRequest = {
            recipientId
        };

        const url = buildUrl(baseUrl, params);
        return this.httpClient.get<GetNumberOfUnreadNotificationsResponse>(url);
    }

    markAsRead(notification: NotificationDto): Observable<boolean> {
        // TODO: Remove in the future
        if (this.config.useTestData) {
            return of(true);
        }

        const url = `${this.config.apiUri}/notifications/${notification.id}/mark-as-read`;
        return this.httpClient
            .patch<boolean>(url, undefined, { observe: 'response' })
            .pipe(map(response => response.ok));
    }

    getNotificationById(notificationId: string): Observable<NotificationDto> {
        // TODO: Remove in the future
        if (this.config.useTestData) {
            const testNotification: NotificationDto = {
                id: notificationId,
                type: NotificationType.Information,
                created: new Date().toISOString(),
                read: false,
                topic: TopicType.ImageExport,
                hidden: false,
                sender: {
                    type: SenderType.System
                },
                recipientId: 'aRecipientId',
                content: {
                    title: 'TEST',
                    message: 'This is a test, continue with your day'
                }
            };
            return of(testNotification);
        }

        const url = `${this.config.apiUri}/notifications/${notificationId}`;

        return this.httpClient.get<NotificationDto>(url);
    }
}
