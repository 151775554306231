@let _notification = notification();
<div
    class="wrapper"
    tabindex="0"
    (keypress.enter)="openLink()"
    (keypress.space)="openLink()"
    (click)="openLink()">
    <div class="icon">
        <ns-sender-icon
            [initials]="initials()"
            [color]="iconColor()" />
    </div>
    <span class="title">
        <strong>
            {{ _notification.content.title }}
        </strong>
    </span>
    <span class="message">
        {{ _notification.content.message }}
    </span>
    <div
        class="read-status-marker"
        tabindex="0"
        (keypress.enter)="markAsRead($event)"
        (keypress.space)="markAsRead($event)"
        (click)="markAsRead($event)">
        <div
            class="marker"
            [class.unread]="!_notification.read"></div>
    </div>
</div>
